
import { defineComponent, ref } from 'vue';
import api$ from '@/services/tau-apis';

export default defineComponent({
  name: 'User',
  setup() {
    const displayTokenModal = ref(false);
    const token = ref('loading...');

    const refreshToken = async () => {
      token.value = 'refreshing...';
      displayTokenModal.value = true;
      const newTokenData = await api$.tau.post('tau-user-token/refresh/', {});
      localStorage.setItem('tau-token', newTokenData.token);
      token.value = newTokenData.token;
    };

    const openTokenModal = async () => {
      token.value = 'loading...';
      displayTokenModal.value = true;
      const tokenData = await api$.tau.get('tau-user-token/');
      token.value = tokenData.token;
    };

    return {
      token,
      displayTokenModal,
      refreshToken,
      openTokenModal,
    };
  },
});
